import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import * as _readableStream2 from "readable-stream";

var _readableStream = "default" in _readableStream2 ? _readableStream2.default : _readableStream2;

import * as _delegates2 from "delegates";

var _delegates = "default" in _delegates2 ? _delegates2.default : _delegates2;

import _tracker from "./tracker.js";
var exports = {};
var util = _util;
var stream = _readableStream;
var delegate = _delegates;
var Tracker = _tracker;

var TrackerStream = exports = function (name, size, options) {
  stream.Transform.call(this, options);
  this.tracker = new Tracker(name, size);
  this.name = name;
  this.id = this.tracker.id;
  this.tracker.on("change", delegateChange(this));
};

util.inherits(TrackerStream, stream.Transform);

function delegateChange(trackerStream) {
  return function (name, completion, tracker) {
    trackerStream.emit("change", name, completion, trackerStream);
  };
}

TrackerStream.prototype._transform = function (data, encoding, cb) {
  this.tracker.completeWork(data.length ? data.length : 1);
  this.push(data);
  cb();
};

TrackerStream.prototype._flush = function (cb) {
  this.tracker.finish();
  cb();
};

delegate(TrackerStream.prototype, "tracker").method("completed").method("addWork").method("finish");
export default exports;