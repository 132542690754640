import * as _events2 from "events";

var _events = "default" in _events2 ? _events2.default : _events2;

import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

var exports = {};
var EventEmitter = _events.EventEmitter;
var util = _util;
var trackerId = 0;

var TrackerBase = exports = function (name) {
  EventEmitter.call(this);
  this.id = ++trackerId;
  this.name = name;
};

util.inherits(TrackerBase, EventEmitter);
export default exports;