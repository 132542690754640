import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import _trackerBase from "./tracker-base.js";
var exports = {};
var util = _util;
var TrackerBase = _trackerBase;

var Tracker = exports = function (name, todo) {
  TrackerBase.call(this, name);
  this.workDone = 0;
  this.workTodo = todo || 0;
};

util.inherits(Tracker, TrackerBase);

Tracker.prototype.completed = function () {
  return this.workTodo === 0 ? 0 : this.workDone / this.workTodo;
};

Tracker.prototype.addWork = function (work) {
  this.workTodo += work;
  this.emit("change", this.name, this.completed(), this);
};

Tracker.prototype.completeWork = function (work) {
  this.workDone += work;
  if (this.workDone > this.workTodo) this.workDone = this.workTodo;
  this.emit("change", this.name, this.completed(), this);
};

Tracker.prototype.finish = function () {
  this.workTodo = this.workDone = 1;
  this.emit("change", this.name, 1, this);
};

export default exports;